import React from 'react'
import { Box } from "@chakra-ui/react";

const Container = ({ children }) => (
    <Box
        width={[
            "100%", // base
            "100%", // 480px upwards
            "xl", // 768px upwards
            "xl", // 992px upwards
        ]}
        paddingX={3}
        margin="auto">
        {children}
    </Box>

)

export default Container