import React from 'react'

import Layout from "../components/layout";
import { Box, Input, Alert, AlertIcon, InputGroup, InputRightElement, Stack, Button, Select, Link as CLink } from "@chakra-ui/react";
import Container from '../components/container';
import axios from 'axios'
import SEO from "../components/seo"
import Header from '../components/header'
import { Link } from 'gatsby'
import { getDomain } from '../service'

function _validation(data) {
    let err = []
    if (data.cabang === '') {
        err.push(...err, 'Pilih Cabang Kampus')
    }
    if (data.nim === '') {
        err.push(...err, 'NIM tidak boleh kosong')
    }
    if (data.password === '') {
        err.push(...err, 'Password tidak boleh kosong')
    }

    return err
}

const Login = () => {
    // check if token availabel
    if (
        typeof window !== 'undefined' &&
        (localStorage.getItem('post_availability') !== null && localStorage.getItem('elearning_availability') !== null)
    ) {
        window.location.href = "/"
    }

    const [show, setShow] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [error, setError] = React.useState([]);
    const [data, setData] = React.useState({
        nim: '',
        password: '',
        cabang: 'kranggan',
    })

    const [post_availability, set_post_availability] = React.useState('UNABAILABLE')
    const [elearning_availability, set_elearning_availability] = React.useState('UNABAILABLE')

    const handleClick = () => setShow(!show);

    function handleLogin(e) {
        e.preventDefault()
        setIsLoading(true)

        let err = _validation(data)

        if (err.length > 0) {
            setError(err)
            setIsLoading(false)
        } else {

            if (data.cabang !== 'yogyakarta') {
                axios.post(`${getDomain('po', data.cabang)}/login/token.php?username=${data.nim}&password=${data.password}&service=moodle_mobile_app`).then((response) => {
                    // console.log(response.data)
                    if (response.status === 200 && typeof response.data.errorcode === 'undefined') {
                        set_post_availability('SUCCESS')
                        localStorage.setItem('d_post', JSON.stringify(response.data))
                    } else {
                        set_post_availability('FAILED')
                        if (response.data.errorcode === "invalidlogin") {
                            setError(['NIM atau Password yang kamu masukan salah'])
                        } else {
                            setError(['Gagal menghubungkan server kampus (POST)'])
                        }

                        setIsLoading(false)
                    }
                }).catch(error => {
                    set_post_availability('SERVER_ERROR')
                    setError(['Gagal menghubungkan server kampus (POST)'])

                    setIsLoading(false)
                })
            } else {
                set_post_availability('FAILED')
            }

            axios.post(`${getDomain('el', data.cabang)}/login/token.php?username=${data.nim}&password=${data.password}&service=moodle_mobile_app`).then((response) => {
                // console.log(response.data)
                if (response.status === 200 && typeof response.data.errorcode === 'undefined') {
                    localStorage.setItem('d_elearning', JSON.stringify(response.data))
                    set_elearning_availability('SUCCESS')
                } else {
                    set_elearning_availability('FAILED')
                    if (response.data.errorcode === "invalidlogin") {
                        setError(['NIM atau Password yang kamu masukan salah'])
                    } else {
                        setError(['Gagal menghubungkan server kampus (E Learning)'])
                    }

                    if (data.cabang === 'yogyakarta') {
                        setIsLoading(false)
                    }
                }
            }).catch(error => {
                setError(['Gagal menghubungkan server kampus (E Learning)'])
                set_elearning_availability('SERVER_ERROR')

                setIsLoading(false)
            })
        }
    }

    React.useEffect(() => {
        if (isLoading) {
            console.log('cek availableity')
            if ((post_availability === 'SUCCESS' || elearning_availability === 'SUCCESS') && (post_availability !== 'UNABAILABLE' && elearning_availability !== 'UNABAILABLE')) {
                if ((post_availability !== 'SERVER_ERROR') && (elearning_availability !== 'SERVER_ERROR')) {
                    if (post_availability === 'SUCCESS') {
                        localStorage.setItem('post_availability', true)
                    } else {
                        localStorage.setItem('post_availability', false)
                    }

                    if (elearning_availability === 'SUCCESS') {
                        localStorage.setItem('elearning_availability', true)
                    } else {
                        localStorage.setItem('elearning_availability', false)
                    }

                    window.location.href = '/'
                }
            }
        }
    }, [post_availability, elearning_availability, isLoading])

    function _setValue(event) {
        setData({ ...data, [event.target.name]: event.target.value })
    }

    return (
        <Layout>
            <SEO title="Quick UMB - Login" />
            <Header title='Login' />
            <Container>
                {error.length > 0 && (
                    <Alert mb={3} status="error">
                        <AlertIcon />
                        {error[0]}
                    </Alert>
                )}
                <form onSubmit={handleLogin}>
                    <Stack mb={3} spacing={3}>
                        <Select value={data.cabang} name="cabang" onChange={_setValue} placeholder="Cabang Kampus">
                            <option value="meruya">Meruya</option>
                            <option value="kranggan">Krangan Bekasi</option>
                            <option value="yogyakarta">Yogyakarta</option>
                        </Select>
                        <Input name="nim" value={data.nim} onChange={_setValue} border='1px' borderColor="#E2E8F0" placeholder="NIM" />
                        <InputGroup size="md">
                            <Input name="password" value={data.password} onChange={_setValue} border='1px' borderColor="#E2E8F0" type={show ? "text" : "password"} placeholder="Password" />
                            <InputRightElement width="4.5rem">
                                <Button colorScheme='blue' border="none" fontSize="12px" fontWeight='normal' h="1.75rem" size="sm" onClick={handleClick}>
                                    {show ? "Hide" : "Show"}
                                </Button>
                            </InputRightElement>
                        </InputGroup>
                    </Stack>
                    <Button type="submit" isLoading={isLoading} cursor="pointer" _hover={{ bg: '#183560' }} bg="primary" border="none" color="white" width="100%">Login</Button>
                    <Box color="primary" textAlign='center' mt={3}>
                        <Stack spacing="1">
                            <CLink isExternal href='https://www.instagram.com/keevnx/'><small>Ada masukan atau error? hubungi Kevin</small></CLink>
                            <Link to='/getstarted'><small>Tentang Aplikasi</small></Link>
                        </Stack>
                    </Box>
                </form>
            </Container>
        </Layout>
    )
}

export default Login